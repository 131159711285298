.mna-popover {
  border: solid 1px #ebecf1;
  margin-left: 20px;

  &__content {
    max-width: 300px;
    width: 100%;
    padding: 20px;
    padding-bottom:0;
    background-color: #fff;
    @media (max-width: 1000px) {
      padding: 8px;
    }
  }

  &__link-account {
    padding: 8px 17px;
    font-size: 16px;
    font-weight: 500;

  }

  &__avatar {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #26ADE4;
    border-radius: 50%;
    background: #C9EAF8;
  }

  &__header {
    display: flex;
    padding-bottom: 12px;
    flex-direction:column;
    align-items:center;

    &-avatar {
      flex: 0 0 auto;
      @media (max-width: 1000px) {
        margin-right: 16px;
      }
      margin-bottom:1em;
    }

    &-name {
      color: $body-text-color;
      font-family: $secondary-font;
      font-size: 13px;
      font-weight: 600;
    }

    &-info {
      justify-content:center!important;

    }
  }

  &__body {
    margin-bottom: 5px;
    &-inputs {
      padding: 25px 0;
      border-bottom: 1px solid #ebecf1;
      border-top: 1px solid #ebecf1;
    }
  }
}

.account-row {
  display:flex;
  justify-content: space-around;
  margin-bottom:0.5em;
  margin-top:0;
  &__button a {
    color:rgb(187, 187, 187);

    &:hover {
      color: #3998c0
    }
  }
}



a.mna-btn.mna-btn--success.text-center.mna-popover__link-account.restore {
    width:100%;
}