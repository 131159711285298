.mna-tooltip {
  &.ui.popup {
    padding: 5px 12px;
    color: #fff;
    background-color: #12345f;
    border: 0;
    &::before {
      border: 0;
      box-shadow: none !important;
      background: #12345f !important;
    }
  }
}
