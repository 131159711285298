.mna-sidebar {
  flex-shrink: 0;
  height: 100%;
  width: $sidebar-width--close;
  padding-top: 30px;
  background-color: #fff;
  padding-right: 5px;
  transition: none;
  position: fixed;
  top: 0;
  bottom: 0;
  .corner_logo {
      display:none;
  }

  &--small {
      display:block;
  }

  &--open {
    width: $sidebar-width--open;
    padding-right: 30px;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    
    .corner_logo {
        display:block;
    

        &--small {
            display:none;
        }
    }
    }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
  }

  &__list {
    list-style-type: none;
    //overflow-x: hidden;
  }

  &__item {
    margin-bottom: 10px;
    white-space: nowrap;

    &:first-of-type {
      margin-left: 30px;
      margin-bottom: 75px;
      margin-top: 3px;
    }
  }

  &__ico {
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 5px 15px 30px;
    border-radius: 0 5px 5px 0;
    color: $text-color;
    transition: color .5s ease-out;
    svg {
      path {
        transition: fill .3s ease-out;
      }
    }

    &--active {
      color: #fff;
      background: $primary-color;
      &:hover {
        color: #fff;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }

    &:hover:not(.mna-sidebar__link--active) {
      z-index: 1;
      color: #fff;
      &:before {
        transform: scaleX(1);
        filter: brightness(110%);
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }

    @include resetLinksStyle;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $primary-color;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform .5s ease-out;
      border-radius: 0 5px 5px 0;
    }
  }

  &__account {
    position: fixed;
    bottom: 0;
    //overflow: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    left: 14px;
    width: 55px;
    border-top: solid .9px #ebecf1;
    background: #fff;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    .mna-sidebar--open & {
      width: 180px;
      left: 30px;
    }

    &-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 0 0;
      width: 100%;
      border: 0;
      color: inherit;
      font-size: 14px;
      font-weight: 500;
      background-color: transparent;
      border-radius: 5px;
      .mna-sidebar--open & {
        flex-direction: row;
        margin: 0 -15px;
        padding: 21px 15px;
        width: calc(100% + 30px);
      }
      &:focus {
        outline: 0;
        background-color: #C9EAF8;
      }
    }

    &-ico {
      margin: 0 0 10px;
      flex: 0 0 auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #C9EAF8;
      color: #26ADE4;
      width: 28px;
      height: 28px;

      .mna-sidebar--open & {
        margin: 0 20px 0 0;
      }
    }

    &-arrows {
      opacity: 0;
      transition: opacity .1s ease-out;
      .mna-sidebar--open & {
        opacity: 1;
        
      }
    }
  }
}

.corner_logo {
    position: absolute;
    top: 80px;
    left: 40px;
    padding-right: 2em;
    width: 73%;

    &--small {
        position: absolute;
        top: 81px;
        left: 31px;
        padding-right: 2em;
        width: 73%;
    }
}

