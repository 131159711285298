.mna-label {
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;

  &.mna-label--matchHeader {
    border-color: #ebecf1;
    background-color: #ebecf1;
  }

  &--primary {
    background-color: rgba(38, 173, 228, 0.3);
    color: $primary-color;
    border: 1px solid $primary-color;
  }

  &--success {
    background-color: rgba(139, 198, 64, 0.3);
    color: $success-color;
    border: 1px solid $success-color;

    &.mna-label--matchHeader {
      color: #358D10;
      border-color: #358D10;
      background-color: #C2DCB7;
    }
  }

  &--complete {
    background-color: rgba(143, 24, 167, 0.3);
    color: $success-color;
    border: 1px solid $success-color;

    &.mna-label--matchHeader {
      color: white;
      border-color: #8d107e;
      background-color: #491C54;
    }
  }

  &--danger {
    background-color: #EBB5C8;
    color: #D42162;
    border: 1px solid #D42162;

    &.mna-label--matchHeader {
      color: #D42162;
      border-color: #D42162;
      background-color: #EBB5C8;
    }
  }

  &--warning {
    background-color: #FFF0CE;
    color: #FFB717;
    border: 1px solid #FFB717;

    &.mna-label--matchHeader {
      color: #FFB717;
      border-color: #FFB717;
      background-color: #FFF0CE;
    }
  }
}