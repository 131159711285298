@import 'components/form/input';
@import 'components/form/button';
@import 'components/sidebar';
@import 'components/popover';
@import 'components/paper';
@import 'components/table';
@import 'components/toast';
@import 'components/tooltip';
@import 'components/pagination';
@import 'components/modal';
@import 'components/form/select';
@import 'components/form/switcher';
@import 'components/page-header';
@import 'components/label';
@import 'components/toolbar';
@import 'components/matches';
@import 'components/restore';
@import 'helpers/utils';

.title {
  color: $body-text-color;
  font-family: inherit;

  &-main {
    font-size: 30px;
  }

  &--collapse {
    font-size: 16px;
  }

  &-page {
    font-size: 30px;
    font-weight: 600;
    font-family: $secondary-font;
  }

  &--ellipsis {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--name {
    //margin-bottom: 5px;
    font-weight: 600;
  }
}

.heading {
  font-family: $secondary-font;
}

.mna {
  &-link {
    &:hover {
      color: darken($success-color, 25%);
      text-decoration: none;
    }
  }

  &-error-msg {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 5px;
    color: $error-color;
  }

  &-collapse {
    background: #fff;

    &__header {
      cursor: pointer;
    }

    &__body {
      margin-top: 32px;
      width: 100%;
    }
  }
}

.accent {
  color: $success-color;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.account-page {
  .mna-collapse {
    border-radius: 10px;
    background-color: #fff;
    padding: 35px;
    border: solid 1px #ebecf1;
    margin-bottom: 20px;
  }
}

.mna-phone {
  .dropdown.ui.mna-phone__dropdown {
    padding: 17px;
    height: auto;
    width: 100px;
    border-radius: 5px;
    border: solid 1px #c3ccd7;
    background-color: #f5f8fe;
    white-space: nowrap;

    >.text {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    >.dropdown.icon {
      position: absolute;
      transform: translateY(-50%);
      right: 8px;
    }
  }

  .ui.input.mna-phone__input-wrap {
    width: 100%;

    input {
      border-radius: 5px;
      font-size: 13px;
      border: solid 1px #c3ccd7;
      font-family: $default-font;
      color: #000;
      flex: auto;
      width: 100%;

      &::placeholder {
        color: #99a9bb;
      }
    }
  }

  .ui.labeled.input>.label:not(.corner) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.type-input-list {

  &--fixedHeight {
    height: 135px;
    flex-wrap: wrap;
  }

  &.flex-row {
    .type-input-list__item {
      &:not(:last-child) {
        margin-right: 20px;
      }

    }
  }

  &.flex-column {
    .type-input-list__item {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
}

.type-list-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 110px;
  margin-bottom: -17px;

  &.column {
    height: auto;
    margin: 0;
    > div {
      padding-bottom: 7px;
    }
  }

  > div {
    padding-bottom: 17px;
    max-width: 200px;
  }
}

.arrow-ico {

  &--left {
    transform: rotate(90deg);
  }

  &--right {
    transform: rotate(-90deg);
  }
}

.icon-svg {
  display: block;

  svg {
    width: 100%;
    height: 100%;
  }
}




button.mna-btn.mna-btn--success-without-bg.btn-export {
    width: 100%;
}

