.table-wrap {
  border: 1px solid #ebecf1;
  border-radius: 10px;
  color: $text-color;
  background: #fff;
  overflow: hidden;
  position: relative;
  &-holder {
    overflow-x: auto;
  }
  .mna-table {
    min-width:1300px!important;
    &__logo {
      img {
        max-height: 25px;
      }
      @media (max-width: 1200px) {
        max-width: 80px;
      }
      @media (min-width: 1201px) {
        height: 28px;
        svg,
        img {
          height: 100%;
          width: auto;
        }
      }
    }
    &__body {
      cursor: pointer;
    }

    &__sectors {
      font-weight: 500;
      color: $body-text-color;
      display: flex;
      flex-wrap: wrap;
      span {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          &::after {
            margin: 0 4px;
            content: '/';
            display: block;
          }
        }
      }
    }

    &.ui.table {
      min-width: 760px;
      background: none;
      border: 0;
      td {
        padding: 20px 5px;
        overflow: visible;
        text-overflow: initial;
        //white-space: pre-wrap;
        vertical-align: middle;

        // ensure all td are vertically centered

        &.one {
          .icon-svg {
            margin: 0 auto;
          }
        }
        &:first-child {
          padding-left: 15px;
        }
      }

      thead {
        th {
          background-color: #fff;
          border: none;
          font-size: 11px;
          font-weight: 500;
          border-bottom: 1px solid #ebecf1;
          padding: 15px 5px;
          color: $text-color;
          text-transform: uppercase;
          &:first-child {
            padding-left: 15px;
          }
        }

        tr:first-child > th:first-child {
          border-top-left-radius: 10px;
        }

        tr:first-child > th:last-child {
          border-radius: 0 10px 0 0;
        }
      }
    }

    &.ui.sortable.table thead th {
      color: $text-color;
      border-left: none;
    }
  }
}




.table-icons {
  color: #99A9BB;
  display:flex;
  height:10px;

  &__notes:hover {
    color:rgba(0, 128, 0, 0.583);
  }

  &__flag:hover{
    color:rgba(223, 10, 10, 0.597);
  }

  &__stale:hover {
    color:#3998c0
  }
}