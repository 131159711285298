
// Container
.react-dropdown-tree-select {
}

// Dropdown Container
.react-dropdown-tree-select .dropdown {
    width:100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
    width: 100%;
}

// Dropdown Box
.react-dropdown-tree-select .dropdown .dropdown-content {
    z-index:99;
}

.react-dropdown-tree-select .tag-item {
    font-family: inherit;
    color: #4d4e4e;;
}

.tag-remove {
    font-size: 13px;
    font-weight: 901;
}

.checkbox-item[type="checkbox"] {
    color:blue;
    background-color:blue;
    border-color: green;
    flood-color: green;
    border-width: 1px;
    border-style: solid;
    border-color: #B3B3B3 #dcddde #dcddde #B3B3B3;
    content: "";
    background-image: linear-gradient(135deg, #B1B6BE 0%, #FFF 100%);
    background-repeat: no-repeat;
    background-position: center;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger:focus {
    outline: none;
}

