@import '../../helpers/vaiables';

.form-group {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &__range {
    display: flex;
    > div {
      flex: 1 1 auto;
      padding: 0 20px 0 0;
      position: relative;
      + div {
        padding: 0 0 0 20px;
      }
      &:first-child {
        &::after {
          right: -11px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          content: '→';
          font-size: 20px;
        }
      }
    }
  }

  &__label {
    font-size: 11px;
    margin-bottom: 5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #4d4e4e;
    > .mna-btn--icon {
      margin-left: 6px;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
  }

  &--inline {
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
    .form-group__label {
      margin-bottom: 0;
      margin-right: 5px;
      color: $body-text-color;
      font-size: 13px;
      text-transform: capitalize;
    }
  }

  &__area {
    display: flex;
    > div {
      flex: 1;
      margin-right: -1px;
      .mna-input,
      .ui.selection.dropdown {
        border-radius: 0;
      }
      &:first-child {
        .mna-input,
        .mna-select.ui.dropdown,
        .custom-select > div {
          border-radius: 5px 0 0 5px !important;
        }
      }
      &:last-child {
        .mna-input,
        .mna-select.ui.dropdown,
        .custom-select > div {
          border-radius: 0 5px 5px 0 !important;
        }
      }
    }

    &-financial {
      @media (max-width: 640px) {
        flex-wrap: wrap;
        .form-group {
          &__label {
            font-size: 10px;
          }
        }
        .mna-input,
        .custom-select {
          min-width: 95px;
        }
      }
    }
  }

  &__error {
    text-align: left;
    color: $danger-color;
    font-size: .9em;
  }
}

.mna-input {
  position: relative;
  border-radius: 5px;
  font-size: 13px;
  padding: 14px;
  max-height: 50px;
  line-height: 50px;
  border: solid 1px $light-color;
  color: #000;
  font-family: $default-font;
  width: 100%;

  &[disabled] {
    background-color: #eee;
    cursor: not-allowed;
  }

  @include updateFocus;

  &--transparent {
    background-color: transparent;
  }

  &--error {
    box-shadow: 0 0 0 1px $error-color;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px $error-color;
    }
  }

  &--success {
    border: solid 1px $success-color;
  }
}

.mna-search-block {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 250px;

  &__ico {
    position: absolute;
    left: 15px;
    z-index: 2;
  }

  .mna-input {
    padding: 17px 16px 17px 49px;
  }
}

.account-radio-list {
  display: flex;
  font-size: 14px;
  font-weight: 600;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &__item {
    position: relative;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__ico {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(200%, -50%);
    fill: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 50%;
    transition: transform .5s ease;
    use {
      fill: $primary-color;
    }
  }

  label {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 10px 45px 10px 15px;
    box-sizing: border-box;
    border-radius: $default-border-radius;
    border: solid 1px #ebecf1;
    background-color: #ffffff;
    color: #99a9bb;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;

    &:checked + label {
      border-color: $primary-color;
      background-color: #c9eaf8;
      color: #40444a;
      .account-radio-list__ico {
        transform: translate(0, -50%);
      }
    }
  }
}

.mna-keyword {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 13px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px $border-color;

  &__item {
    position: relative;
    font-size: 13px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 13px;
    background-color: $text-color;
    padding: 5px 33px 5px 10px;
    margin-right: 8px;
  }
  &__input {
    outline: none;
    border: none;
    height: 28px;
    flex: 1 1 auto;
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    text-align: center;
    font-size: 7px;
    padding: 0;
    border-radius: 50%;
    border: 0;
  }
}

.mna-textarea {
  position: relative;

  textarea {
    padding: 17px;
    border: solid 1px $border-color;
    border-radius: $default-border-radius;
    color: #000;
    resize: none;
    width: 100%;
    font-family: $default-font;
    min-height: 128px;
    box-sizing: border-box;
    font-size: 13px;

    @include updateFocus;
  }

  &__icon {
    position: absolute;
    left: 14px;
    top: 15px;
  }

  &--large {
    flex: 1 1 auto;
    display: flex;
    textarea {
      padding: 20px;
    }
  }

  &--icon {
    textarea {
      padding-left: 50px;
    }
  }
}

.toggle-form-group {
  position: relative;
  overflow: hidden;

  &__label {
    color: #99a9bb;
    font-weight: 500;
    &:before {
      content: "";
      display: inline-block;
      margin-right: 13px;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: solid 1px #c3ccd7;
    }

    &:after {
      content: "";
      position: absolute;
      left: -4px;
      top: -2px;
      transform: translate(-100%, 0);
      width: 16px;
      height: 22px;
      background-image: url("../../../img/checked.svg");
      transition: transform .3s ease;
    }
  }
  &__input {
    display: none;
    &:checked {
      + label {
        color: #4d4e4e;
        .toggle-form-group__ico {
          opacity: 1;
        }
        &:after {
          transform: translate(0, 0);
        }
      }
    }
  }

  &__ico {
    opacity: 0;
    position: absolute;
    left: -4px;
    top: 0;
  }
}

@include placeholder {
  color: $text-color;
}
