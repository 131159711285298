.text {
  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-uppercase {
    text-transform: uppercase;
  }
}

.ml-auto {
  margin-left: auto;
}

.full-height {
  height: 100%;
}

.p-0 {
  padding: 0 !important;
}

.flex {
  display: flex;
  &-row {
    flex-direction: row;
  }
  &-column {
    flex-direction: column;
  }
  &-center {
    align-items: center;
  }
  &-end {
    justify-content: flex-end;
  }
  &-content-center {
    justify-content: center;
  }
  &-wrap {
    flex-wrap: wrap;
  }
}

.visibility-hidden {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.margin {
  &-bottom {
    margin-bottom: 2rem;

    &__medium {
      margin-bottom: 1em;
    }
    
    &__small {
      margin-bottom: .5em;
    }
    
    &-removed {
      margin-bottom: 0;
    }
  }
  
  &-top {
    margin-top: 2rem;
  
    &__small {
      margin-top: .5em;
    }
    
    &-removed {
      margin-top: 0;
    }
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}