.modal {

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eaeaea;
  }

  &-body {
    padding: 1rem;
    display:flex;
    flex-direction: column;

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #eaeaea;

      &-label {
        font-weight: bold;
      }
      &-value {
        font-weight: normal;
        & a {
          font-weight:bold;
        }
      }
      &:last-child {
        margin-bottom: 2em;
      }
    }

    &-button {
      width: 100%;
      text-align: center;
      margin-top:1em;


    }

    &-matches {
      width:100%;
      display:flex;
      flex-wrap:wrap;
      justify-content: space-around;
      align-items: center;
      overflow-y: auto;
      height:20em;
    }

    &-item {
      display:flex;
      width:30%;
      margin-bottom:1.5em;
      flex-direction:column;
      border: 1px solid #eaeaea;
      padding:1rem;
      justify-content: space-between;
      align-items: center;
      border-radius:5%;
      margin:1.5em;

      &-col {
        display:flex;
        margin-bottom:0.5em;
        text-align:center;

        &:first-child {
          font-weight:900;
        }
      }
    }

    &-footer {
      width:100%;
      display:flex;
      justify-content: space-evenly;

      height:50px;
    }

  }
  &-content {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    padding: 20px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.empty {
  height:auto!important;
}