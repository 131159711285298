.paper {
  border-radius: 10px;
  background-color: #fff;
  padding: 35px;
  border: solid 1px #ebecf1;
  @media (max-width: 991px) {
    &:last-child {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 790px) {
    padding: 16px;
    &:last-child {
      margin-bottom: 16px;
    }
  }
  
  &:not(:last-child) {
    margin-bottom: 30px;
    @media (max-width: 790px) {
      margin-bottom: 16px;
    }
  }
  
  &--medium {
    max-width: 540px;
    width: 100%;
  }

  &--action {
    margin-top: 35px;
    @media (max-width: 790px) {
      margin-top: 16px;
    }
    .title {
      margin-bottom: 7px;
    }
  }
}
