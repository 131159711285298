.matchesFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.matches-masonry {
  margin: 0 -12px;
  width: calc(100% + 24px);
}

@media (max-width: 400px) {
  .matches-masonry {
    width: 174%;
  }
}

.matches {
  padding: 25px;
  width: calc(50% - 24px);
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px !important;

  @media screen and (max-width: 1022px) {
    width: 96.5%;
  }
  @media screen and (max-width: 700px) {
    width: 94%;
  }

  cursor: pointer;

  &_card__heading {
    color: #636363;
  }

  &__header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    height: 5px;

    > div {
      display: flex;
      align-items: flex-start;
    }

    .mna-label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -26px;
      left: -26px;
      height: 30px;
      width: calc(100% + 52px);
      text-align: center;
      border: none;
      border-radius: 10px 10px 0 0;
      padding: 5px 0;
    }
  }

  &__icons {
    position: absolute;
    width: 100%;
    height: 50px;
    padding: 0 25px;
    top: 28px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  &__logo {
    flex: 1;
    display: flex;
    align-items: center;

    img {
      max-height: 30px;
      width: auto;
    }

    &:last-child {
      img {
        margin-left: auto;
        display: block;
      }
    }
  }

  &__header-icon {
    margin: 0;
    padding: 14px;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    border: 1px solid #ebecf1;
    background: white;
  }

  &__title {
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    color: $body-text-color;
    margin: 0 !important;
  }

  &__title-box {
    width: 60%;
    position: relative;

    > div {
      flex: 1;
    }
  }

  &__title-business {
    display: flex;
    align-items: flex-start;

    > div {
      flex: 1;
    }
  }

  &__title-label {
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
      width: auto;
      height: 14px;
    }
  }

  &__body {
    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
  }

  &__description {
    flex: 1 1 auto;
    padding: 25px;

    @media (max-width: 1000px) {
      flex-basis: 100%;
      border-top: 1px solid #ebecf1;
    }
  }

  &__details {
    flex: 0 0 50%;
    padding: 15px;
    padding-right: 0;
    padding-top: 104px;
    border-right: 1px solid #ebecf1;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    flex-direction: column;

    &-subtitle {
      font-size: 9px;
    }

    &:last-child {
      border-right: none;
      align-items: center;

      .matches__details-title,
      .matches__title,
      .matches__title-label,
      li div {
        text-align: left;
        justify-content: flex-end;
      }
    }
  }

  &__details-no-logo {
    flex: 0 0 50%;
    padding: 25px;
    border-right: 1px solid #ebecf1;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    &:last-child {
      border-right: none;
      align-items: flex-end;

      .matches__details-title,
      .matches__title,
      .matches__title-label,
      li div {
        text-align: left;
        justify-content: flex-end;
      }
    }
  }

  &__details-title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }

  &__details-list {
    padding: 0;
    margin: 0 0 -12px;
    max-width: 190px;

    li {
      overflow: hidden;
      padding: 0 0 14px;

      strong {
        width: 70px;
        float: left;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 5px;
        font-size: 11px;
      }

      div {
        overflow: hidden;
        color: $body-text-color;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    margin-bottom: -25px;

    &-left {
      display: flex;
      margin-left: 5px;
      font-weight: 500;

      &-tightened {
        display: flex;
        padding-bottom: 1em;
        padding-top: 1em;
      }
    }

    button:first-child {
      margin-right: 12px;

      @media only screen and (max-width: 1424px) {
        margin-right: 0;
        margin-bottom: 5px;

        @media screen and (max-width: 1022px) {
          margin-bottom: 0;
          margin-right: 12px;

          @media only screen and (max-width: 764px) {
            margin-right: 0;
            margin-bottom: 5px;
          }
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      color: $success-color;

      svg path {
        fill: $success-color !important;
      }

      &.denied {
        color: $danger-color;

        svg path {
          fill: $danger-color !important;
        }
      }
    }

    .icon-svg {
      display: inline-block;
      margin-right: 8px;
    }
  }

  &-info {
    margin-bottom: 28px;

    &__title {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 11px;
      margin-bottom: 10px;
      color: $light-color;
    }

    &__data {
      font-size: 13px;
      font-weight: 500;
      color: $text-color;

      > a {
        color: $text-color;
      }
    }
  }
}

.card-icon {
  position: absolute;
  top: 15%;
  background-color: white;
  border-style: solid;
  border-color: #e6e6e6;
  border-width: 1px;
  border-radius: 50%;
  padding: 7px;
  width: 45px;
  height: 45px;
  text-align: center;
  text-align: center;
  padding-top: 9px !important;
}
.criteriaSelect {
  padding-top: 10px;
  font-size: 1em;
  color: #8a8a8a !important;
}

@-moz-document url-prefix() {
  .rodal-dialog {
    top: 3em !important;
    bottom: 66% !important;
  }

  .criteriaSelect {
    padding-top: 10px !important;
  }
}

.matches__details-list li div {
  overflow: hidden;
  color: #4d4e4e;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

@media (max-width: 1450px) {
  .matches__footer {
    display: flex;
    flex-direction: column;
    margin: 0;
    &-left {
      padding: 1em;
    }
  }
  .matches__details-list-li {
    display: flex;
    flex-direction: column;
  }

  .card__footer-button {
    height: 2em !important;
    margin-bottom: 2em;
  }

  .card__footer-button {
    height: 100%;
  }

  .matches {
    padding-bottom: 3em !important;
  }

  .matches__footer button:first-child {
    margin-right: 12px !important;
  }
}

@media (max-width: 1022px) {
  .matches__footer {
    display: flex;
    flex-direction: row;
    margin-bottom: -25px;
  }

  .card__footer-button {
    margin-bottom: 0em;
  }
}

@media (max-width: 850px) {
  .matches__footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .card__footer-button {
    margin-bottom: 2em;
  }
}

.market_status {
  background-color: #f7f7f7;
  border-style: ridge;
  padding: 6px;
  width: 100px;
  min-width: 100px;
  text-align: center;
  margin-right: 12px;
}

.match_type {
  background-color: #f7f7f7;
  margin-right: auto;
  border-style: ridge;
  padding: 6px;
  margin-left: 5px;
  //margin-left:2%;
  width: 100px;
  text-align: center;
}

.matches_card {
  &__heading {
    padding: 2em;
  }
}

.tight {
  padding-left: 11px !important;
  padding-top: 0 !important;
}

.hidden {
  display: none;
}

.card {
  display: flex;
  justify-content: center;
  border-bottom: solid thin rgba(204, 204, 204, 0.217);

  &-left {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-right: 1.4em;
    width: 100%;
    border-style: solid;
    border-right: solid thin rgba(204, 204, 204, 0.217);
    font-weight: 500;

    &-logo {
      text-align: left;
      margin-bottom: 30px;
      margin-left: 2em;

      &-img {
        border-style: none;
        object-fit: contain;
        height: 30px;
        &-no-logo {
          display: none;
        }
      }
    }

    &-heading {
      display: flex;
      justify-content: space-between;

      &-copy {
        flex: 1;
        flex-basis: 6%;
        display: flex;
        &-icon {
          align-self: center;
        }
      }
      &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 2;
        flex-basis: 80%;
        &-type {
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 500;
        }

        &-company {
          word-break: break-word;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #4d4e4e;
          margin: 0 !important;
        }
      }
      /*
            &-icons {
                flex:3;
                display:flex;
                flex-direction:column;
                justify-content:center;
              
                &--icon {
                  text-align:center;
                }
            }
            */
    }

    &-body {
      padding: 9%;
      padding-bottom: 0;
    }
  }

  &-right {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1.2em;
    width: 100%;
    border-style: solid;

    &-flag {
      position: absolute;
      right: 3%;
      //margin-top:1em;

      &:hover {
        color:rgba(223, 10, 10, 0.597);
      }
    }

    &-logo {
      text-align: left;
      margin-bottom: 30px;

      &-img {
        border-style: none;
        object-fit: contain;
        height: 30px;
        margin-left: 11%;

        &-no-logo {
          display: none;
        }
      }
    }

    &-heading {
      display: flex;
      justify-content: space-between;

      &-copy {
        flex: 1;
        flex-basis: 10%;
        display: flex;
        &-icon {
          align-self: center;
        }
      }
      &-title {
        flex: 2;
        flex-basis: 80%;
        &-type {
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 500;
        }

        &-company {
          word-break: break-word;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #4d4e4e;
          margin: 0 !important;
        }
      }

      &-icons {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-icon {
          text-align: right;
        }
      }
    }

    &-body {
      padding: 9%;
      padding-left: 11%;
      padding-bottom: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    &-left {
      display: flex;
      color: red;
      font-weight: 500;
    }

    &-button {
      height: 50%;
    }

    &-box {
      display: flex;
      margin-left: 10px;
      flex-wrap: nowrap;
      justify-content: flex-end;

      @media only screen and (max-width: 1385px) {
        margin-bottom: 20px;

        @media screen and (max-width: 1022px) {
          margin-bottom: 0;
          margin-right: 12px;

          @media only screen and (max-width: 822px) {
            margin-right: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.owner-circle {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  color: #fff;
  background: white;
  color: #99a9bb;
}

.minimised-card-flag {
  top:20%;
  right: 3.4%;
}