.mna-toast {
  .Toastify__toast-container {
    width: 160px;
  }
  .Toastify__toast--success {
    background-color: $success-color;
    width: auto;
  }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    margin-left: 0;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
  }
}
