@mixin updateFocus() {
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $focus-color;
  }
}

@mixin buttonFocus() {
  &:focus,
  &:active {
    filter: brightness(90%);
    outline: none;
    box-shadow: 0 0 0 1px currentColor;
  }
}

@mixin resetLinksStyle() {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
