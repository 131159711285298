@import '../helpers/vaiables';

.mna-pagination {
  position: relative;
  margin-left: 30px;
  .ui.pagination.menu {
    padding: 0 28px 0 42px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-family: $default-font;
    font-size: 13px;
    color: $text-color;

    .item {
      color: currentColor;
      outline: none;
      &:not( [type="lastItem"]) {
        &:before {
          display: none;
        }
      }
      &:hover {
        color: currentColor;
        background-color: transparent !important;
      }

      &.active {
        box-shadow: none;
        border: 1px solid $border-color;
        border-radius: 5px;
        min-width: 50px;
        height: 50px;
        padding: 5px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          color: currentColor;
        }
      }
    }

    [type="lastItem"] {
      display: flex;
      flex-flow: row-reverse;
      &:after {
        content: 'of';
        display: inline-block;
        margin-right: 5px;
      }
    }
    [type="prevItem"],
    [type="nextItem"] {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -12px;
      text-indent: -9999px;
      min-width: auto;
      width: 24px;
      height: 24px;
      outline: none;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 24px 24px;
      &:hover {
        opacity: 0.8;
      }
    }
    [type="prevItem"] {
      background-image: url("../../img/arrowIco.svg");
      transform: rotate(90deg);
    }
    [type="nextItem"] {
      background-image: url("../../img/arrowIco.svg");
      transform: rotate(-90deg);
      left: auto;
      right: 0;
    }
  }
  .pagina-active {
    position: absolute;
    left: 42px;
    top: 0;
    input {
      font-family: $default-font !important;
      width: 50px;
      height: 50px;
      background: #fff;
      border: 1px solid #c3ccd7;
      text-align: center;
      padding: 5px;
      box-sizing: border-box;
    }
  }
}
