.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
  }
  > div {
    display: flex;
    align-items: center;
  }
  
  .form-group {
    margin-bottom: 0;
  }

  &__pages {
    @media (max-width: 1000px) {
      margin-top: 15px;
      .form-group {
        width: 100%;
        justify-content: space-between;
      }
      .mna-pagination {
        margin-left: auto;
      }
    }
    .custom-select {
      width: 115px;
    }
  }
}
