.mna-modal {
  background-color: #eee;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;

  & button {
    margin-top: 1em;
  }

  &.ui.modal {
    @media (min-width: 992px) {
      width: 705px;
    }
  }

  &__header {
    background-color: #fff;
    box-shadow: 0 12px 44px 0 rgba(64, 68, 74, 0.07);
    padding: 30px 0;

    & .icon-svg {
      position: absolute;
      left: 5%;
    }

    & svg {
      position:absolute;
      left:8%;
    }

    .title {
      font-weight: 500;
    }
  }

  &__content {
    padding: 55px;
    text-align: center;
    @media (max-width: 480px) {
      padding: 30px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    .mna-btn {
      width: 150px;
      &:first-of-type {
        margin-right: 15px;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    &--alt {
      padding: 0 55px 40px;
      justify-content: space-between;
      @media (max-width: 680px) {
        flex-wrap: wrap;
        justify-content: center;
        button {
          flex: 1 1 auto;
        }
        .mna-modal__actions-buttons {
          display: flex;
          order: -1;
          width: 100%;
          justify-content: center;
          margin: 0 0 20px;
        }
      }
      @media (max-width: 480px) {
        padding: 0 30px 30px;
      }
    }
  }
}

.ui.modals.dimmer {
  background-color: rgba(64, 68, 74, 0.5);
  .ui.modal {
    transition: none;
    animation: fadeIn 0.5s linear forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.group-label {
  cursor: pointer;

  & :hover {
    font-weight:900;
    background-color:gray;
  }
}

.matches-modal {
  &__header {
    background-color: #fff;
    box-shadow: 0 12px 44px 0 rgba(64, 68, 74, 0.07);
    padding: 30px 0;
  }

  &__content {
    padding: 55px;
    text-align: center;
    @media (max-width: 480px) {
      padding: 30px;
    }
  }

  &__footer {
    padding: 30px;
    text-align: center;
    @media (max-width: 480px) {
      padding: 30px;
    }
  }
}