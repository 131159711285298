.restore {
  &-select {
    border-radius: 5px;
    margin-right: 3%;
    padding: 1.2em;
    padding-right: 2.3em;
    background-color: white;
    border-color: rgba(128, 128, 128, 0.231);
    font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', 'Icons16',
      sans-serif;
  }

  &-leftpage {
    margin-right: 1em;
    padding-right: 1em;
    font-size: 1.4em;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: gray;
  }

  &-rightpage {
    margin-left: 1em;
    padding-left: 1em;
    font-size: 1.4em;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: gray;
  }

  &-table {
    margin-top: 1.3em;
    font-family: inherit;
    background-color: white;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    border: 0 !important;
    border-radius: 11px;

    &-header {
      text-transform: uppercase;
      text-align: left;
      color: #99a9bb;
      vertical-align: middle;

      &__row {
        border-bottom: solid;
        border-width: thin;
        border-color: #00000021;
      }

      &__column {
        border: 0 !important;
        vertical-align: middle;
        padding: 0.7em !important;
        font-weight: 500;
        font-size: 0.85em;
        padding-top: 0.8em !important;
        padding-bottom: 0.8em !important;
      }
    }

    &-body {
      text-align: left;
      color: #4d4e4e;
      vertical-align: middle;

      &__row {
      }

      &__column:first-child {
        font-weight: 500;
        padding: 20px;
      }

      &__column:nth-child(3) {
        font-weight: 500;
      }

      &__column {
        border: 0 !important;
        vertical-align: middle;
      }
    }
  }
}

.pagination {
  text-align: right;
}

.header-bar {
  width:100%;
  display: flex;
  justify-content: space-between;
  &-left {

  }

  &-right {
    width:50%;
    text-align: right;

  }
}

.restore-select {
  font-size: 1em;
}

tr.restore-table-body__row {
  border-bottom: 1px solid rgba(34, 36, 38, 0.12);
}

td span {
  display: flex;
}

.description {
  margin-bottom: 8px;
}
.description--header {
  flex: 0 0 13em;
}

.description--body {
  font-weight: 300;
}

@media (max-width: 1200px) {
  td span {
    flex-direction: column;
  }

  .description--header {
    flex: 0;
  }
}

.rodal-dialog {
  width: 600px !important;
  height: 0 !important;
  display: table !important;
  padding: 0em !important;
}

.rodal-form {
  padding-top: 0em;
  padding-bottom: 2em;
}

.hiddenbutton {
  display: none;
}
