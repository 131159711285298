.mna-btn {
  padding: 15px;
  color: #fff;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: 0.5s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include buttonFocus;
  .icon-svg {
    margin-right: 7px;
    svg {
      path {
        fill: currentColor !important;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .7;
  }

  &--success {
    background-color: $success-color;
    &:hover {
      filter: brightness(110%);
      color: #fff;
    }
  }

  &--medium {
    max-width: 150px;
    width: 100%;
  }

  &--success-without-bg {
    color: $success-color;
    border: 1px solid $success-color;
    background-color: transparent;
    &.checked,
    &:hover {
      background-color: $success-color;
      color: #fff;
    }
  }

  &--danger {
    background-color: $danger-color;
    &:hover {
      background-color: darken($danger-color, 5%);
      color: #fff;
    }
  }
  
  &--danger-without-bg {
    color: $danger-color;
    border: 1px solid $danger-color;
    background-color: transparent;
    &.checked,
    &:hover {
      background-color: $danger-color;
      color: #fff;
    }
  }

  &--transparent {
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    text-indent: 20px;
    border: 0;
    color: #99a9bb;
    padding: 5px;
  }

  &--login {
    margin: 50px 0;
  }

  &--icon {
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &-removed {
      @media (max-width: 765px) {
        margin: 0 auto 15px !important;
        display: block;
        width: 24px;
      }
    }
  }

  &--ico {
    position: absolute;
    z-index: 5;
    right: 10px;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    @include buttonFocus;
  }
  &--filter {
    margin-left: 15px;
    border: 1px solid $border-color;
    color: $text-color;
    max-width: 125px;
    font-size: 13px;

    .icon-svg {
      margin-left: 10px;
    }
  }
  &--filter-active {
    background-color: #8bc640;
    color: #fff;
    border-color: #8bc640;
    svg {
      color: currentColor;
      g[mask] {
        fill: currentColor;
      }
    }
  }
  
  &--small {
    padding: 6px 20px;
    min-width: 120px;
  }
}
