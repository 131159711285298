$error-color: #db798c;
$primary-color: #26ade4;
$sidebar-link--hover: rgba(38, 173, 228, 0.2);
$success-color: #8bc640;

$focus-color: rgba(0, 123, 255, 0.5);
$default-font: 'Roboto', sans-serif;
$secondary-font: 'Titillium Web', 'Roboto', sans-serif;

$light-color: #c3ccd7;
$text-color: #99a9bb;
$body-bg: #f5f5f5;
$body-text-color: #4d4e4e;

$default-border-radius: 5px;
$danger-color: #d42162;

$sidebar-width--open: 240px;
$sidebar-width--close: 80px;

$border-color: $light-color;
