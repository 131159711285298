@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap&subset=cyrillic,cyrillic-ext');
@import url('../../../node_modules/@blueprintjs/core/lib/css/blueprint.css');
@import url('./semantic.css');
@import 'helpers/reset';
@import 'font';

@import 'helpers/vaiables';
@import 'helpers/mixin';
@import 'core';

html {
  height: 100%;
}

body {
  min-height: 100%;
}

img {
  max-width: 100%;
}

img,
a {
  display: inline-block;
  vertical-align: middle;
}

.mna-body {
  font-size: 13px;
  color: $text-color;
  font-family: $default-font;
  background-color: $body-bg;
  overflow-x: hidden;
  .bp3-popover {
    box-shadow: 0 8px 24px 0 rgba(37, 38, 42, 0.08);
  }
  > * {
    font-family: $default-font;
  }

  &__root {
    display: flex;
    flex-direction: column;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $default-font;
    margin: 0 0 10px;
  }
}

.App {
  display: flex;
  flex: 1 0 auto;
}

.main-wrap {
  width: 100%;
  padding: 50px 40px;
  transition: padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  padding-left: calc(#{$sidebar-width--close} + 40px);
  @media (max-width: 1000px) {
    padding: 50px 20px;
    padding-left: calc(#{$sidebar-width--close} + 20px);
  }
  @media (max-width: 790px) {
    padding: 24px 10px;
    padding-left: calc(#{$sidebar-width--close} + 10px);
  }
  &.sidebar--open {
    padding-left: calc(#{$sidebar-width--open} + 40px);
    @media (max-width: 1000px) {
      padding-left: calc(#{$sidebar-width--open} + 20px);
    }
    @media (max-width: 790px) {
      padding-left: calc(#{$sidebar-width--open} + 10px);
    }
  }
}

.financial-data {
  &__add-row {
    img {
      margin-right: 10px;
    }
    color: $success-color;
    border-radius: 0;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: $default-font;
  font-size: initial;
}

button,
select {
  cursor: pointer;
}

.spinner {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.modal-list {
  text-align: left;
  padding-bottom: 1em;
}

.criteriaSelect {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  color: gray;
  padding: 5px;
}

.table-user {
  width: 50px;
}

.tracker_edit {
  padding-top: 1em;
  padding-bottom: 5em;
  position: absolute;
  text-decoration: wavy;
  font-style: italic;
}

.button-row {
  display: flex;
  margin-bottom: 2em;

  &__button {
    font-size: 1px;
    margin: 2em;
    margin-top: 0;
  }
}

.loadingContainer {
  width: 100%;
  text-align: center;
}

.loadingIcon {
  width: 20%;
}

.MuiDivider-middle {
  display: none;
  margin-left: 10000px;
}

.ui.fitted.toggle.checkbox.mna-switcher {
  display: flex;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.MuiBox-root {
  padding-left: 8px;
  color: rgb(24, 24, 24);
  min-height: 40px;
  font-size: 13px;
}

.node:last-child {
  display: none;
}

.subsector-checked {
  font-weight: 900;
}

.report {
  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__col {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 20px;
    text-align: center;

    &:nth-child(1) {
      // nice border with fade

      // box shadow
      box-shadow: #1fa133 0px 0px 1px 1px inset;
    }
    &:nth-child(2) {
      border-color: #63cf5c;
      box-shadow: #63cf5c 0px 0px 1px 1px inset;
    }
    &:nth-child(3) {
      border-color: #9aec65;
      box-shadow: #9aec65 0px 0px 1px 1px inset;
    }
    &:nth-child(4) {
      border-color: #501887;
      box-shadow: #501887 0px 0px 1px 1px inset;
    }
  }

  &__title {
    font-size: 13px;
    font-weight: bold;
    color: rgba(24, 24, 24, 0.768);
    padding-bottom: 5px;
  }

  &__subtitle {
    font-size: 13px;
    font-weight: bold;
    color: rgb(24, 24, 24);
    padding-bottom: 5px;
  }

  &__graph-slider {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.datepicker {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
  flex-wrap: wrap;

  &-dropdown {
    // nice looking dropdown
    border-radius: 0;
    border: 0;
    font-size: 1em;
    //box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15);
    margin-top: 0;
    padding: 0;

    z-index: 100;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 0 1;
    margin-left: 1em;
    padding: 5px;
    border-bottom: 1px solid #ddd;
    color: rgb(24, 24, 24);
  }

  &-input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    font-size: 13px;
    color: rgb(24, 24, 24);
    background-color: hsl(0, 0%, 100%);
    outline: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    margin-left: 1em;
  }

  &-button {
    margin-left: 1em;
    // green button white text
    background-color: #8bc640;
    color: #fff;
    font-weight: bold;
    // no border
    border: none;
    border-radius: 10%;

    &:hover {
      background-color: #49bd32;
    }
  }
}

.mna-pagebutton {
  background-color: white;
  border: none;
}

.loadmore {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.half {
  width: 4%;
}

.pagina-active div input {
  font-size: 13px;
}

.smaller-font {
  font-size: 0.8em !important;
}
